import React from 'react'
import { AppBar, Grid, Tab, Tabs } from '@material-ui/core'

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

interface Props {
  children?: React.ReactNode
}

export const TabContainer: React.FC<Props> = (props: Props) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue)
  }

  const labels: string[] = []
  React.Children.map(props.children, (element) => {
    if (!React.isValidElement(element)) return

    labels.push(element.props.label)
  })

  function renderChildren(): unknown {
    return React.Children.map(props.children, (child, index) => {
      if (!React.isValidElement(child)) return child
      else
        return React.cloneElement(child, {
          value: value,
          index: index,
        })
    })
  }

  return (
    <Grid item xs={12}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          scrollButtons="auto"
          aria-label="tabs"
        >
          {labels.map((label, index) => (
            <Tab label={label} {...a11yProps(index)} key={index} />
          ))}
        </Tabs>
      </AppBar>
      {renderChildren()}
    </Grid>
  )
}
