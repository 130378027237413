import WebsocketAction from '../WebsocketAction'
import { WebsocketActionType } from '../ActionType'

export interface UpdateGuess extends WebsocketAction {
  type: number
  index: 1 | 2 | 3,
  value: 1 | 2 | 3 | 4,
  socket: { send: boolean }
}

export function updateGuess(
  index: 1 | 2 | 3,
  value: 1 | 2 | 3 | 4,
): UpdateGuess {
  return {
    type: WebsocketActionType.UpdateGuess,
    index: index,
    value: value,
    socket: { send: true },
    local: true,
  }
}
