import WebsocketAction from '../WebsocketAction'
import { WebsocketActionType } from '../ActionType'

export interface EnterGame extends WebsocketAction {
  type: number
  gameId: string
  socket: { send: boolean }
}

export function enterGame(id: string): EnterGame {
  return {
    type: WebsocketActionType.EnterGame,
    gameId: id,
    socket: { send: true },
    local: true,
  }
}
