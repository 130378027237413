import React from 'react'
import Clue from '../../../../models/Clue'
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faKeyboard, faLaptopCode } from '@fortawesome/free-solid-svg-icons'
import './ClueSheet.scss'

interface Props {
  white: boolean
  clues: Clue[]
}

const HeaderRow: React.FC = () => {
  return (
    <TableContainer component="span">
      <Table size="small" className="header">
        <TableBody>
          <TableRow>
            <TableCell className="clue">

            </TableCell>
            <Tooltip title="Code associé">
              <TableCell align="center" className="number">
                <FontAwesomeIcon icon={faCheck} />
              </TableCell>
            </Tooltip>
            <Tooltip title="Réponse de l'équipe">
              <TableCell align="center" className="number">
                <FontAwesomeIcon icon={faKeyboard} />
              </TableCell>
            </Tooltip>
            <Tooltip title="Décodage adverse">
              <TableCell align="center" className="number">
                <FontAwesomeIcon icon={faLaptopCode} />
              </TableCell>
            </Tooltip>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const ClueSheet: React.FC<Props> = (props: Props) => {
  const className = 'clue-sheet ' + (props.white ? 'white' : '')
  return (
    <Grid container item xs={12} className={className}>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <HeaderRow />
        </Grid>
        <Grid item sm={6} md={4} className="xs-none">
          {
            props.clues.length > 1 &&
            <HeaderRow />
          }
        </Grid>
        <Grid item md={4} className="xs-none sm-none">
          {
            props.clues.length > 2 &&
            <HeaderRow />
          }
        </Grid>
        {
          props.clues.map((clue,  i) => {
            if (clue == null) return <div />
            return (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <TableContainer component="span">
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" className="clue">
                          { clue.clue1 }
                        </TableCell>
                        <TableCell align="center" className="number resp">
                          { clue.indication1 }
                        </TableCell>
                        <TableCell align="center" className="number guess">
                          { clue.guess1 }
                        </TableCell>
                        <TableCell align="center" className="number guess foe">
                          { clue.foeGuess1 }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className="clue">
                          { clue.clue2 }
                        </TableCell>
                        <TableCell align="center" className="number resp">
                          { clue.indication2 }
                        </TableCell>
                        <TableCell align="center" className="number guess">
                          { clue.guess2 }
                        </TableCell>
                        <TableCell align="center" className="number guess foe">
                          { clue.foeGuess2 }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className="clue">
                          { clue.clue3 }
                        </TableCell>
                        <TableCell align="center" className="number resp">
                          { clue.indication3 }
                        </TableCell>
                        <TableCell align="center" className="number guess">
                          { clue.guess3 }
                        </TableCell>
                        <TableCell align="center" className="number guess foe">
                          { clue.foeGuess3 }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )
          })
        }
      </Grid>
    </Grid>
  )
}

export default ClueSheet
