import WebsocketAction from '../WebsocketAction'
import { WebsocketActionType } from '../ActionType'

export interface ResetGame extends WebsocketAction {
  type: number
  socket: { send: boolean }
}

export function resetGame(): ResetGame {
  return {
    type: WebsocketActionType.Reset,
    socket: { send: true },
    local: true,
  }
}
