import Player from '../models/Player'

export function getPlayer(
  playerName: string,
  players: Player[],
): Player | undefined {
  return players.find((p) => p.name === playerName)
}

export function getIsWhiteTeam(
  playerName?: string,
  players?: Player[],
): boolean {
  if (players == null || playerName == null) return false
  const player = getPlayer(playerName, players)
  if (player == null) return false
  return player.whiteTeam
}

export function getIsSpy(playerName?: string, players?: Player[]): boolean {
  if (players == null || playerName == null) return false
  const player = getPlayer(playerName, players)
  if (player == null) return false
  return player.isSpy
}
