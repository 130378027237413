export enum GameStateEnum {
  Lobby = 0,
  FindClues = 1,
  GuessBoth = 2,
  GuessOne = 3,
  ResultOne = 4,
  GuessTwo = 5,
  ResultGlobal = 6,
  Finished = 7,
}
