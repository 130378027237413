import React, { Component, ReactNode } from 'react'
import AppState from '../../../store/AppState'
import { connect } from 'react-redux'
import { Button, Grid } from '@material-ui/core'
import PlayerList from '../common/PlayerList'
import './Lobby.scss'
import { launchGame } from '../../../store/websocketActions/LaunchGame'
import { Action } from '@reduxjs/toolkit'
import { getIsWhiteTeam } from '../../../utils/players'

interface Props {
  gameId: string
  whiteTeam: boolean
  launching: boolean

  launchGame: () => Action
}

class Lobby extends Component<Props> {
  render(): ReactNode {
    if (this.props.launching) {
      return (
        <Grid
          container
          item
          justify="center"
          xs={12}
          sm={10}
          md={7}
          className="lobby content-card"
        >
          <Grid item xs={12}>
            <span>Lancement...</span>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container item xs={12} direction="column" justify="flex-start">
        <Grid container item justify="center">
          <Grid
            container
            item
            justify="center"
            xs={12}
            sm={10}
            md={7}
            className="lobby content-card"
          >
            <Grid item xs={12}>
              <h1>Decrypto</h1>
            </Grid>
            <Grid item xs={12}>
              <h2>Lobby de la partie {this.props.gameId}</h2>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '25px' }}>
              <h2>
                En attente, lancez la partie une fois que tout le monde est prêt
              </h2>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '25px' }}>
              <Button
                onClick={this.props.launchGame}
                variant="contained"
                color={this.props.whiteTeam ? 'primary' : 'secondary'}
              >
                {'Lancer la partie'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item justify="center">
          <PlayerList xs={12} sm={7} md={5} lg={3} />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  gameId: state.gameId ?? '',
  whiteTeam: getIsWhiteTeam(state.playerName, state.players),
  launching: state.loading,
})

export default connect(mapStateToProps, { launchGame })(Lobby)
