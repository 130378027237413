import React, { ReactNode } from 'react'
import './App.scss'
import { ThemeProvider } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { theme } from '../utils/theme'

interface Props {
  children: ReactNode[]
}

const App: React.FC<Props> = (props: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <Grid container className="main-grid content">
        <Grid container item xs={12} direction="column" justify="space-between">
          <Grid container item style={{ marginBottom: '30px' }}>
            {props.children}
          </Grid>
          <Grid item className="credits">
            <span className="title">Decrypto v0.1 (08/2021)</span>
            {' - '}
            <span className="implem">Implémentation : Maxence Blomme</span>
            {' - '}
            <span className="source">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://gitlab.com/maxence.blomme/decrypto"
              >
                Code Source
              </a>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default App
