import React, { Component } from 'react'
import {
  Button,
  FormControlLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import './PlayerRegistration.scss'
import { Action } from '@reduxjs/toolkit'
import AppState from '../../../store/AppState'
import { connect } from 'react-redux'
import { registerPlayer } from '../../../store/websocketActions/RegisterPlayer'

interface Props {
  gameId?: string
  errCount: number
  playerNames: string[]

  registerPlayer: (name: string, whiteTeam: boolean) => Action
}

interface State {
  name: string
  whiteTeam?: boolean
  registering: boolean
  error: boolean
}

class PlayerRegistration extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      name: '',
      whiteTeam: undefined,
      registering: false,
      error: false,
    }

    this.register = this.register.bind(this)
    this.checkPlayerName = this.checkPlayerName.bind(this)
  }

  register() {
    this.setState({ registering: true, error: false })
    if (this.state.whiteTeam != null && this.checkPlayerName(this.state.name)) {
      this.props.registerPlayer(this.state.name, this.state.whiteTeam)
    } else {
      this.setState({ registering: false, error: true })
    }
  }

  checkPlayerName(name: string) {
    return (
      name &&
      name.length > 0 &&
      name.length <= 16 &&
      !this.props.playerNames.includes(name)
    )
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.errCount !== prevProps.errCount) {
      this.setState({ registering: false, error: true })
    }
  }

  render() {
    if (this.state.registering) {
      return (
        <Grid
          container
          item
          justify="center"
          xs={12}
          sm={10}
          md={7}
          className="player-registration content-card"
        >
          <Grid item xs={12}>
            <span>Enregistrement...</span>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid
        container
        item
        justify="center"
        xs={12}
        sm={10}
        md={7}
        className="player-registration content-card"
      >
        <Grid item xs={12}>
          <h1>Decrypto</h1>
        </Grid>
        <Grid item xs={12}>
          <h2>Bienvenue sur la partie {this.props.gameId}</h2>
        </Grid>
        <Grid item xs={12}>
          {this.state.error && (
            <span style={{ color: 'red' }}>
              {
                'Erreur : ce joueur existe déja ou les informations rentrées sont invalides. Ton nom doit avoir entre 1 et 16 caractères, et n\'oublie pas de choisir une couleur si tu n\'es pas spectateur !'
              }
            </span>
          )}
        </Grid>
        <Grid item xs={12} style={{ marginTop: '25px' }}>
          <h2>Quel est ton nom ?</h2>
        </Grid>
        <Grid item xs={11} sm={10} md={8} lg={6}>
          <Input
            fullWidth
            onChange={(event) => this.setState({ name: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '25px' }}>
          <h2>Quelle équipe veux-tu rejoindre ?</h2>
        </Grid>
        <Grid item>
          <RadioGroup
            aria-label="color"
            name="color1"
            onChange={(event) =>
              this.setState({ whiteTeam: event.target.value === 'B' })
            }
          >
            <FormControlLabel
              value="B"
              control={<Radio color="primary" />}
              label="Blanc"
            />
            <FormControlLabel
              value="N"
              control={<Radio color="secondary" />}
              label="Noir"
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '25px' }}>
          <Button
            onClick={this.register}
            variant="contained"
            color={this.state.whiteTeam ? 'primary' : 'secondary'}
            disabled={
              this.state.whiteTeam == null ||
              !this.state.name ||
              !this.checkPlayerName(this.state.name)
            }
          >
            {'M\'enregistrer'}
          </Button>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  errCount: state.errCount,
  gameId: state.gameId,
  playerNames: state.players != null ? state.players.map((p) => p.name) : [],
})

export default connect(mapStateToProps, { registerPlayer })(PlayerRegistration)
