import AppState from './AppState'

export const initialState: AppState = {
  errCount: 0,
  loading: false,
  resetting: false,
  gameId: undefined,
  game: undefined,
  words: undefined,
  playerName: undefined,
  players: [],
  whiteClues: {},
  blackClues: {},
  whiteFinished: false,
  blackFinished: false,
  guesses: { guess1: undefined, guess2: undefined, guess3: undefined, fixed: false },
  chat: [],
}
