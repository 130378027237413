import { Dictionary } from '@reduxjs/toolkit'
import Clue from '../models/Clue'

export function clueDict(clues: Clue[] | undefined): Dictionary<Clue> | undefined {
  if (clues == null) return undefined
  const out = {} as Dictionary<Clue>
  for (const clue of clues) {
    out[clue.turn] = clue
  }
  return out
}

export function copyClueDict(dict: Dictionary<Clue>): Dictionary<Clue> {
  const out = {} as Dictionary<Clue>
  for (const key in dict) {
    out[key] = { ...dict[key] } as Clue
  }
  return out
}

export function clueDictToList(dict: Dictionary<Clue>): Clue[] {
  const clues = [] as Clue[]
  for (const key in dict) {
    clues.push(dict[key] as Clue)
  }
  return clues.sort((c1, c2) => c1.turn < c2.turn ? -1 : c1.turn > c2.turn ? 1 : 0)
}
