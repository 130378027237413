import React, { Component, ReactNode } from 'react'
import './GameList.scss'
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import * as H from 'history'
import PublicGame from '../../models/PublicGame'
import { getPublicGames } from '../../api/game'

interface Props {
  history: H.History
}

interface State {
  games: PublicGame[]
}

class GameList extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      games: [],
    }
  }

  componentDidMount(): void {
    getPublicGames().then((data) => {
      this.setState({
        games: data,
      })
    })
  }

  render(): ReactNode {
    return (
      <Grid
        container
        item
        xs={12}
        sm={10}
        md={7}
        justify="center"
        className="content-card game-list"
      >
        <Grid item xs={12}>
          <h2>Parties ouvertes :</h2>
        </Grid>
        <Grid item xs={12} sm={11} md={8} lg={6}>
          <TableContainer component={'span'}>
            <Table aria-label="games">
              <TableBody>
                {this.state.games.map((game, i) => (
                  <TableRow key={i}>
                    <TableCell align="right" className="handwriting game-name">
                      {game.gameId}
                    </TableCell>
                    <TableCell align="center" className="players-amount">
                      {game.playerCount === -1 ? 0 : game.playerCount} joueur
                      {game.playerCount !== 1 ? 's' : ''}
                    </TableCell>
                    <TableCell align="center" className="join-game">
                      <Button
                        onClick={() =>
                          this.props.history.push('/game/' + game.gameId)
                        }
                        variant="contained"
                        color="secondary"
                      >
                        Rejoindre
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    )
  }
}

export default GameList
