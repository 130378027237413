import React from 'react'
import { Grid } from '@material-ui/core'
import './PlayerRegistration.scss'
import PlayerRegistration from './PlayerRegistration'
import PlayerList from '../common/PlayerList'

const RegistrationPage: React.FC = () => {
  return (
    <Grid container item xs={12} direction="column" justify="flex-start">
      <Grid container item justify="center">
        <PlayerRegistration />
      </Grid>
      <Grid container item justify="center">
        <PlayerList xs={12} sm={7} md={5} lg={3} />
      </Grid>
    </Grid>
  )
}

export default RegistrationPage
