import WebsocketAction from '../WebsocketAction'
import { WebsocketActionType } from '../ActionType'

export interface ValidateGuess extends WebsocketAction {
  type: number
  value1: 1 | 2 | 3 | 4,
  value2: 1 | 2 | 3 | 4,
  value3: 1 | 2 | 3 | 4,
  socket: { send: boolean }
}

export function validateGuess(
  value1: 1 | 2 | 3 | 4,
  value2: 1 | 2 | 3 | 4,
  value3: 1 | 2 | 3 | 4,
): ValidateGuess {
  return {
    type: WebsocketActionType.ValidateGuess,
    value1: value1,
    value2: value2,
    value3: value3,
    socket: { send: true },
    local: true,
  }
}
