import React from 'react'
import ReactDOM from 'react-dom'
import './global.scss'
import { BrowserRouter, Route } from 'react-router-dom'
import Index from './components/index/Index'
import Game from './components/game/Game'
import App from './components/App'
import Axios from 'axios'
import { Provider } from 'react-redux'
import configureGameStore from './store/config'

if (process.env.REACT_APP_BACKEND_URL) {
  Axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL
}

const store = configureGameStore()

ReactDOM.render(
  <Provider store={store}>
    {/*<React.StrictMode>*/}
    <BrowserRouter>
      <App>
        <Route exact path="/" component={Index} />
        <Route path="/game/:id" component={Game} />
      </App>
    </BrowserRouter>
    {/*</React.StrictMode>*/}
  </Provider>,
  document.getElementById('root'),
)
