import PublicGame from '../models/PublicGame'
import Axios from 'axios'

export async function createGame(
  gameId: string,
  privateGame: boolean,
): Promise<{ status: number }> {
  console.log(Axios.defaults)
  return Axios.post('api/game', {
    gameId: gameId,
    private: privateGame,
  }).catch((error) => {
    return error.response
  })
}

export async function getPublicGames(): Promise<PublicGame[]> {
  return (await Axios.get('api/game/public')).data
}

export async function gameExists(gameId: string | null): Promise<boolean> {
  if (!gameId) {
    console.log(gameId)
    return false
  }
  return !!(await Axios.get(`api/game/${gameId}/exists/`)).data
}
