import React, { Component, ReactNode } from 'react'
import './CreateGameForm.scss'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
} from '@material-ui/core'
import * as H from 'history'
import { createGame } from '../../api/game'

interface Props {
  history: H.History
}

interface State {
  invalid: boolean
  creating: boolean
  created: boolean
  existed: boolean
  private: boolean
  id: string
}

class CreateGameForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      invalid: false,
      creating: false,
      created: false,
      existed: false,
      id: '',
      private: true,
    }

    this.validName = this.validName.bind(this)
    this.createGame = this.createGame.bind(this)
    this.created = this.created.bind(this)
    this.changePrivate = this.changePrivate.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  validName(): boolean {
    const name = this.state.id
    return (
      name.length > 0 &&
      name.length <= 32 &&
      name.match(/^[a-zA-Z0-9]+$/) !== null
    )
  }

  createGame(): void {
    const id = this.state.id
    this.setState({ invalid: false })
    if (!this.validName()) {
      this.setState({ invalid: true, creating: false })
      return
    }

    createGame(id, this.state.private).then((response) => {
      if (response.status >= 400) {
        this.setState({
          // Invalid name
          invalid: true,
          creating: false,
          created: false,
          id: '',
        })
      } else {
        this.setState({
          existed: response.status === 200,
          creating: false,
          created: true,
          id: id,
        })
      }
    })
  }

  created(): boolean {
    return this.state.created && this.state.id !== ''
  }

  changePrivate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ private: event.target.checked })
  }

  submitForm(event: React.ChangeEvent<HTMLFormElement>): void {
    this.setState({ creating: true })
    this.createGame()

    // Prevent form redirection
    event.preventDefault()
  }

  render(): ReactNode {
    if (this.state.creating) {
      return (
        <Grid
          container
          item
          justify="center"
          xs={12}
          sm={10}
          md={7}
          className="create-game-form content-card"
        >
          <Grid item xs={12}>
            <h2>Création...</h2>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid
        container
        item
        justify="center"
        xs={12}
        sm={10}
        md={7}
        className="create-game-form content-card"
      >
        <form onSubmit={this.submitForm}>
          <Grid item xs={12}>
            <h1>Décrypto</h1>
          </Grid>
          <Grid item xs={12}>
            {!this.created() && <h2>Créer / rejoindre une partie :</h2>}
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ marginTop: '5px' }}
          >
            <Grid item xs={11} sm={10} md={8} lg={6}>
              {this.created() && !this.state.existed && (
                <h2>La partie {this.state.id} a été créée !</h2>
              )}
              {this.created() && this.state.existed && (
                <h2>La partie {this.state.id} existe déjà !</h2>
              )}
              {!this.created() && (
                <Input
                  fullWidth
                  onChange={(event) =>
                    this.setState({ id: event.target.value })
                  }
                  color="primary"
                />
              )}
            </Grid>
          </Grid>
          {!this.created() && (
            <Grid item xs={12} style={{ marginTop: '5px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.private}
                    onChange={this.changePrivate}
                    name="private"
                    color="secondary"
                  />
                }
                label="Partie privée"
              />
            </Grid>
          )}
          <Grid item xs={12} style={{ marginTop: '10px' }}>
            {!this.created() && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!this.validName()}
              >
                Créer une partie
              </Button>
            )}
            {this.created() && (
              <Button
                onClick={() =>
                  this.props.history.push('/game/' + this.state.id)
                }
                variant="contained"
                color="primary"
              >
                Rejoindre la partie
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            {this.state.invalid && (
              <span style={{ color: 'red' }}>
                Erreur : le nom de la partie doit être un seul mot avec
                uniquement des chiffres et des lettres
              </span>
            )}
          </Grid>
        </form>
      </Grid>
    )
  }
}

export default CreateGameForm
