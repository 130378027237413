import React, { Component, ReactNode } from 'react'
import AppState from '../../../store/AppState'
import Player from '../../../models/Player'
import Clue from '../../../models/Clue'
import { connect } from 'react-redux'
import { toggleSpy } from '../../../store/websocketActions/ToggleSpy'
import { sendClues } from '../../../store/websocketActions/SendClues'
import { Action } from '@reduxjs/toolkit'
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@material-ui/core'
import { getIsSpy, getIsWhiteTeam } from '../../../utils/players'
import './SpyPanel.scss'
import { clueDictToList } from '../../../utils/clues'

interface Props {
  isSpy: boolean
  whiteTeam: boolean
  players: Player[]
  playerName: string
  turn: number
  clues: Clue[]
  words: string[]
  whiteClueSent: boolean
  blackClueSent: boolean
  
  toggleSpy: (isSpy: boolean) => Action
  sendClues: (clue1: string, clue2: string, clue3: string) => Action
}

interface State {
  indication1: number
  indication2: number
  indication3: number
  clue1: string
  clue2: string
  clue3: string
}

class SpyPanel extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    
    const clue = props.clues.find(c => c.turn === props.turn)

    this.state = {
      indication1: clue?.indication1 ?? 0,
      indication2: clue?.indication2 ?? 0,
      indication3: clue?.indication3 ?? 0,
      clue1: '',
      clue2: '',
      clue3: '',
    }

    this.toggleSpy = this.toggleSpy.bind(this)
    this.sendClues = this.sendClues.bind(this)
    this.validClues = this.validClues.bind(this)
    this.indicationWord = this.indicationWord.bind(this)
  }
  
  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.clues.length != this.props.clues.length) {
      const clue = this.props.clues.find(c => c.turn === this.props.turn)
      this.setState({
        indication1: clue?.indication1 ?? 0,
        indication2: clue?.indication2 ?? 0,
        indication3: clue?.indication3 ?? 0,
      })
    }
  }

  toggleSpy() {
    const confirmText =
      'Votre équipe a déjà un espion pour ce tour, voulez-vous vraiment passer espion ?'
    const myTeamSpies = this.props.players.filter(
      (p) => p.whiteTeam === this.props.whiteTeam && p.isSpy,
    )
    if (
      this.props.isSpy ||
      (!this.props.isSpy &&
        (myTeamSpies.length === 0 || window.confirm(confirmText)))
    )
      this.props.toggleSpy(!this.props.isSpy)
  }

  sendClues() {
    if (this.validClues()) {
      this.props.sendClues(this.state.clue1, this.state.clue2, this.state.clue3)
    }
  }

  validClues() {
    return (
      this.state.clue1.length > 0 &&
      this.state.clue1.length < 65 &&
      this.state.clue2.length > 0 &&
      this.state.clue2.length < 65 &&
      this.state.clue3.length > 0 &&
      this.state.clue3.length < 65
    )
  }
  
  indicationWord(indication: number): string {
    if (indication == 0) return ''
    return this.props.words[indication - 1]
  }
  
  clueSent(): boolean {
    return (this.props.whiteTeam && this.props.whiteClueSent) ||
        (!this.props.whiteTeam && this.props.blackClueSent)
  }

  render(): ReactNode {
    const whiteSpies = this.props.players.filter(
      (p) => p.whiteTeam && p.isSpy,
    ) as (Player | null)[]
    const blackSpies = this.props.players.filter(
      (p) => !p.whiteTeam && p.isSpy,
    ) as (Player | null)[]

    if (whiteSpies.length === 0) whiteSpies.push(null)
    if (blackSpies.length === 0) blackSpies.push(null)

    const rows = []
    for (let i = 0; i < Math.max(whiteSpies.length, blackSpies.length); i++) {
      rows.push([whiteSpies[i], blackSpies[i]])
    }

    return (
      <Grid
        container
        item
        justify="center"
        xs={12}
        className="spy-panel content-card"
      >
        <Grid item xs={12}>
          <h2>1 - Trouver des indices</h2>
        </Grid>
        <Grid item xs={12}>
          <h3>Espions dans chaque équipe</h3>
        </Grid>
        <Grid item xs={11} className="table">
          <TableContainer component={'span'}>
            <Table aria-label="spies">
              <TableBody>
                {
                  (this.props.whiteClueSent || this.props.blackClueSent) &&
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>{ this.props.whiteClueSent ? 'INDICE ENVOYÉ' : '' }</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>{ this.props.blackClueSent ? 'INDICE ENVOYÉ' : '' }</TableCell>
                  </TableRow>
                }
                {rows.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell
                      align="center"
                      className={
                        'white cell ' + (row[0] && i === 0 ? '' : 'err-spy')
                      }
                    >
                      {row[0]
                        ? row[0].name +
                          (row[0].name === this.props.playerName ? ' (me)' : '')
                        : i === 0
                          ? 'Aucun'
                          : ''}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        'black cell ' + (row[1] && i === 0 ? '' : 'err-spy')
                      }
                    >
                      {row[1]
                        ? row[1].name +
                          (row[1].name === this.props.playerName ? ' (me)' : '')
                        : i === 0
                          ? 'Aucun'
                          : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={this.toggleSpy} disabled={ this.clueSent() }>
            Passer {this.props.isSpy ? 'joueur' : 'espion'}
          </Button>
        </Grid>
        {this.props.isSpy && (
          <Grid container item justify="center" xs={11}>
            <Grid item xs={12}>
              <h3>
                Code à faire deviner : <span className="code">{this.state.indication1} {this.state.indication2} {this.state.indication3}</span>
              </h3>
            </Grid>
            <Grid container item justify="center" xs={12}>
              <Grid item xs={12} md={5}>
                <h3>
                  Indice pour <span className="code">{ this.state.indication1 }</span>
                </h3>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  fullWidth
                  label={ this.indicationWord(this.state.indication1) }
                  color="secondary"
                  onChange={(event) =>
                    this.setState({ clue1: event.target.value })
                  }
                  disabled={ this.clueSent() }
                />
              </Grid>
            </Grid>
            <Grid container item justify="center" xs={12}>
              <Grid item xs={12} md={5}>
                <h3>
                  Indice pour <span className="code">{ this.state.indication2 }</span>
                </h3>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  fullWidth
                  label={ this.indicationWord(this.state.indication2) }
                  color="secondary"
                  onChange={(event) =>
                    this.setState({ clue2: event.target.value })
                  }
                  disabled={ this.clueSent() }
                />
              </Grid>
            </Grid>
            <Grid container item justify="center" xs={12}>
              <Grid item xs={12} md={5}>
                <h3>
                  Indice pour <span className="code">{ this.state.indication3 }</span>
                </h3>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  fullWidth
                  label={ this.indicationWord(this.state.indication3) }
                  color="secondary"
                  onChange={(event) =>
                    this.setState({ clue3: event.target.value })
                  }
                  disabled={ this.clueSent() }
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.sendClues}
                disabled={!this.validClues() || this.clueSent()}
              >
                Envoyer les indices
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  isSpy: getIsSpy(state.playerName, state.players),
  whiteTeam: getIsWhiteTeam(state.playerName, state.players),
  players: state.players,
  playerName: state.playerName != null ? state.playerName : '',
  turn: state.game != null ? state.game.turn : 0,
  clues: getIsWhiteTeam(state.playerName, state.players) ? clueDictToList(state.whiteClues) : clueDictToList(state.blackClues),
  words: state.words ?? [],
  whiteClueSent: state.whiteFinished,
  blackClueSent: state.blackFinished,
})

export default connect(mapStateToProps, { toggleSpy, sendClues })(SpyPanel)
