export enum ActionType {
  SetGameId = 'setGameId',
  ResetChat = 'resetChat',
}

export enum WebsocketActionType {
  WebsocketConnect = -1,
  EnterGame = 0,
  LeaveGame = 1,
  RegisterPlayer = 2,
  RemovePlayer = 3,
  GetPlayers = 4,
  Reset = 5,
  LaunchGame = 6,
  ToggleSpy = 7,
  SendClues = 8,
  GetUnfilledClues = 9,
  GetFullClues = 10,
  UpdateGuess = 11,
  ValidateGuess = 12,
  GetWords = 13,
  SendChat = 14,
  GetResult = 15,
  ContinueGame = 16,
}
