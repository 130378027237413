import React, { Component, ReactNode } from 'react'
import './Game.scss'
import { Button, Grid } from '@material-ui/core'
import { gameExists } from '../../api/game'
import * as H from 'history'
import { connect } from 'react-redux'
import AppState from '../../store/AppState'
import { Action } from '@reduxjs/toolkit'
import { enterGame } from '../../store/websocketActions/EnterGame'
import { resetGame } from '../../store/websocketActions/ResetGame'
import RegistrationPage from './registration/RegistrationPage'
import { GameStateEnum } from '../../utils/GameStateEnum'
import Lobby from './lobby/Lobby'
import Board from './board/Board'
import './Game.scss'

interface Props {
  history: H.History
  match: { params: { id: string } }

  gameId?: string
  gameState?: number
  playerRegistered: boolean
  resetting: boolean

  enterGame: (id: string) => Action
  resetGame: () => Action
}

interface State {
  gameId: string
}

class Game extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.draw = this.draw.bind(this)
    this.resetGame = this.resetGame.bind(this)
  }

  componentDidMount(): void {
    const gameId = this.props.match.params.id

    if (gameId) {
      gameExists(gameId).then((exists) => {
        if (exists) {
          this.props.enterGame(gameId)
        } else {
          this.props.history.push('/')
        }
      })
    } else {
      this.props.history.push('/')
    }
  }
  
  resetGame() {
    if (confirm('Voulez-vous vraiment recommencer la partie ?')) this.props.resetGame()
  }

  draw(): ReactNode {
    if (!this.props.playerRegistered) {
      return <RegistrationPage />
    }
    switch (this.props.gameState) {
      case GameStateEnum.Lobby:
        return <Lobby />
      case GameStateEnum.FindClues:
      case GameStateEnum.GuessBoth:
      case GameStateEnum.GuessOne:
      case GameStateEnum.ResultOne:
      case GameStateEnum.GuessTwo:
      case GameStateEnum.ResultGlobal:
      case GameStateEnum.Finished:
        return <Board />
    }
  }

  render(): ReactNode {
    if (this.props.resetting) {
      return (
        <Grid
          container
          item
          justify="center"
          xs={12}
          sm={10}
          md={7}
          className="game content-card"
        >
          <Grid item xs={12}>
            <h2>Création...</h2>
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid container justify="center" className="game">
        <Grid item xs={12}>
          {this.draw()}
        </Grid>
        {
          this.props.playerRegistered && this.props.gameState !== GameStateEnum.Lobby &&
          <Grid item xs={12} className="reset">
            <Button
              variant="contained"
              color="primary"
              onClick={this.resetGame}
            >
              Recommencer la partie
            </Button>
          </Grid>
        }
      </Grid>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  gameId: state.gameId,
  gameState: state.game?.state,
  playerRegistered: !!state.playerName,
  resetting: state.resetting,
})

export default connect(mapStateToProps, { enterGame, resetGame })(Game)
