import React, { Component, ReactNode } from 'react'
import { Action } from '@reduxjs/toolkit'
import ChatMessage from '../../../models/ChatMessage'
import AppState from '../../../store/AppState'
import { connect } from 'react-redux'
import { resetChat } from '../../../store/actions/ResetChat'
import { Grid, TextField } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { sendChat } from '../../../store/websocketActions/SendChat'
import { animateScroll } from 'react-scroll'
import './Chat.scss'

interface Props {
  chat: ChatMessage[]
  resetChat: () => Action
  sendChat: (SendChatMessage: string) => Action
  
  disabled: boolean
}

interface State {
  message: string
}

class Chat extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    
    this.state = {
      message: '',
    }
    
    this.sendMessage = this.sendMessage.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  
  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.chat.length !== this.props.chat.length) {
      animateScroll.scrollToBottom({ containerId: 'chat-content', duration: 100 })
    } 
  }

  componentWillUnmount() {
    this.props.resetChat()
  }
  
  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ message: event.target.value })
  }
  
  sendMessage(event?: React.FormEvent<HTMLFormElement>) {
    if (!this.props.disabled) {
      this.props.sendChat(this.state.message)
      this.setState({ message: '' })
    }
    
    // Prevent form redirection
    event?.preventDefault()
  }

  render(): ReactNode {
    const test = [] as ChatMessage[]
    for (let i = 0; i < 5; i++) {
      test.push({ playerName: 'Bijour', message: 'Je pense que c\'est 1 2 3' })
    } 
    
    return (
      <Grid container item xs={12} className="chat">
        <Grid item xs={12} className="content" id="chat-content">
          {
            this.props.chat.length === 0 &&
              <p style={{ textAlign: 'center' }}>
                Le chat de l&apos;équipe est actuellement vide
              </p>
          }
          {
            this.props.chat.map((msg, index) => (
              <p key={index}>
                <span className="player-name">{msg.playerName}</span> : {msg.message}
              </p>
            ))
          }
        </Grid>
        {
          !this.props.disabled &&
          <Grid item xs={12} className="send-form">
            <form onSubmit={this.sendMessage}>
              <TextField
                variant="outlined"
                placeholder="Message..."
                className="message-text"
                value={this.state.message}
                onChange={this.handleChange}
              />
              <FontAwesomeIcon icon={faPaperPlane} className="send-button" onClick={() => (this.sendMessage())} />
              <button type="submit" hidden />
            </form>
          </Grid>
        }
      </Grid>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  chat: state.chat,
})

export default connect(mapStateToProps, { resetChat, sendChat })(Chat)
