import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#e6e6e6',
      main: '#e0e0e0',
      dark: '#9c9c9c',
      contrastText: '#000',
    },
    secondary: {
      light: '#ff7675',
      main: '#4b4f51',
      dark: '#343738',
      contrastText: '#fff',
    },
  },
})
