import { configureStore, getDefaultMiddleware, Store } from '@reduxjs/toolkit'
// eslint-disable-next-line @typescript-eslint/no-var-requires
import reduxWebsocket from 'react-redux-websocket'
import camelMiddleware from 'redux-camel'
import appReducer from './appReducer'
import ReconnectingWebSocket from 'reconnecting-websocket'

export default function configureGameStore(): Store {
  let host = window.location.host
  if (process.env.REACT_APP_BACKEND_URL) {
    const res = /^https?:\/\/(.+)$/.exec(process.env.REACT_APP_BACKEND_URL)
    if (res) {
      host = res[1]
    }
  }

  const socket = new ReconnectingWebSocket('ws://' + host + '/ws')

  const store = configureStore({
    reducer: appReducer,
    middleware: [
      reduxWebsocket(socket),
      camelMiddleware(),
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
    ],
    preloadedState: undefined,
    enhancers: [],
  })

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./appReducer', () => store.replaceReducer(appReducer))
  }

  return store
}
