import AppState from './AppState'
import WebsocketAction from './WebsocketAction'
import { initialState } from './initialState'
import { Action, Dictionary } from '@reduxjs/toolkit'
import { ActionType, WebsocketActionType } from './ActionType'
import { SetGameId } from './actions/SetGameId'
import { EnterGameMessage } from './messageActions/EnterGameMessage'
import { RegisterPlayerMessage } from './messageActions/RegisterPlayerMessage'
import { RemovePlayerMessage } from './messageActions/RemovePlayerMessage'
import { LaunchGameMessage } from './messageActions/LaunchGameMessage'
import { extractWords } from '../utils/words'
import { GetWordsMessage } from './messageActions/GetWordsMessage'
import { ToggleSpyMessage } from './messageActions/ToggleSpyMessage'
import { getIsWhiteTeam, getPlayer } from '../utils/players'
import { SendCluesMessage } from './messageActions/SendCluesMessage'
import { GetUnfilledCluesMessage } from './messageActions/GetUnfilledCluesMessage'
import Clue from '../models/Clue'
import Player from '../models/Player'
import UpdateGuessMessage from './messageActions/UpdateGuessMessage'
import ValidateGuessMessage from './messageActions/ValidateGuessMessage'
import { amFoe } from '../utils/team'
import { clueDict, copyClueDict } from '../utils/clues'
import { SendChatMessage } from './messageActions/SendChatMessage'
import { GetResultMessage } from './messageActions/GetResultMessage'
import ContinueGameMessage from './messageActions/ContinueGameMessage'
import { ResetGameMessage } from './messageActions/ResetGameMessage'
import { GameStateEnum } from '../utils/GameStateEnum'

export default function appReducer(
  state: AppState = initialState,
  action: Action,
): AppState {
  if ((action as WebsocketAction).local) return state
  
  let whiteClues: Dictionary<Clue>, blackClues: Dictionary<Clue>
  let players: Player[]
  
  switch (action.type) {
    // Frontend actions
    case ActionType.SetGameId:
      const setGameAction = action as SetGameId
      return { ...state, gameId: setGameAction.payload }

    // Websocket message actions
    case WebsocketActionType.WebsocketConnect:
      return state
    case WebsocketActionType.EnterGame:
      const enterGameMessage = action as EnterGameMessage
      return {
        ...state,
        gameId: enterGameMessage.gameId,
        players: enterGameMessage.players,
      }
    case WebsocketActionType.LeaveGame:
      return { ...initialState }
    case WebsocketActionType.Reset:
      const resetGameMessage = action as ResetGameMessage
      if (resetGameMessage.resetting) return { ...state, resetting: true }
      return {
        ...initialState,
        gameId: state.gameId,
        players: state.players.map(p => ({ ...p, isSpy: false })),
        playerName: state.playerName,
        game: resetGameMessage.game,
      }
    case WebsocketActionType.RegisterPlayer:
      const registerPlayerMessage = action as RegisterPlayerMessage
      if (registerPlayerMessage.error !== 0) {
        return { ...state, errCount: state.errCount + 1 }
      }
      if (registerPlayerMessage.self) {
        const whiteClues = clueDict(registerPlayerMessage.clues?.filter(c => c.whiteTeam)) ?? state.whiteClues
        const blackClues = clueDict(registerPlayerMessage.clues?.filter(c => !c.whiteTeam)) ?? state.blackClues
        const whiteClueSent = registerPlayerMessage.whiteClueSent
        const blackClueSent = registerPlayerMessage.blackClueSent
        return {
          ...state,
          playerName: registerPlayerMessage.player.name,
          players: [...state.players, registerPlayerMessage.player],
          game: registerPlayerMessage.game,
          whiteClues: whiteClues,
          blackClues: blackClues,
          whiteFinished: whiteClueSent != null ? whiteClueSent : false,
          blackFinished: blackClueSent != null ? blackClueSent : false,
        }
      } else {
        return {
          ...state,
          players: [...state.players, registerPlayerMessage.player],
        }
      }
    case WebsocketActionType.RemovePlayer:
      const removePlayerMessage = action as RemovePlayerMessage
      return {
        ...state,
        players: state.players.filter(
          (p) => p.name !== removePlayerMessage.name,
        ),
      }
    case WebsocketActionType.GetWords:
      const getWordsMessage = action as GetWordsMessage
      return { ...state, words: extractWords(getWordsMessage.words) }
    case WebsocketActionType.LaunchGame:
      const launchGameMessage = action as LaunchGameMessage
      if (launchGameMessage.launching) {
        return { ...state, loading: true }
      }
      return {
        ...state,
        game: launchGameMessage.game,
        words: extractWords(launchGameMessage.words),
        loading: false,
        whiteFinished: false,
        blackFinished: false,
      }
    case WebsocketActionType.ToggleSpy:
      const toggleSpyMessage = action as ToggleSpyMessage
      players = state.players.map((p) => ({ ...p }))
      const index = players.map((p) => p.name).indexOf(toggleSpyMessage.name)
      players[index].isSpy = toggleSpyMessage.isSpy
      const out = { ...state, players: players }

      if (toggleSpyMessage.name === state.playerName) {
        const player = getPlayer(state.playerName, players)
        const clues = player?.whiteTeam
          ? copyClueDict(state.whiteClues)
          : copyClueDict(state.blackClues)
        if (toggleSpyMessage.isSpy && toggleSpyMessage.indications) {
          clues[toggleSpyMessage.indications.turn] = toggleSpyMessage.indications
        } else {
          clues[toggleSpyMessage.game.turn] = undefined
        }

        if (player?.whiteTeam) out.whiteClues = clues
        else out.blackClues = clues
      }

      return out
    case WebsocketActionType.SendClues:
      const sendCluesMessage = action as SendCluesMessage
      return {
        ...state,
        whiteFinished: sendCluesMessage.whiteTeam || state.whiteFinished,
        blackFinished: !sendCluesMessage.whiteTeam || state.blackFinished,
      }
    case WebsocketActionType.GetUnfilledClues:
      const getUnfilledCluesMessage = action as GetUnfilledCluesMessage
      const whiteUnfilledClue = getUnfilledCluesMessage.clues.find(c => c.whiteTeam) ?? {} as Clue
      const blackUnfilledClue = getUnfilledCluesMessage.clues.find(c => !c.whiteTeam) ?? {} as Clue
      whiteClues = copyClueDict(state.whiteClues)
      blackClues = copyClueDict(state.blackClues)
      whiteClues[whiteUnfilledClue.turn] = whiteUnfilledClue
      blackClues[blackUnfilledClue.turn] = blackUnfilledClue
      return {
        ...state,
        whiteClues: whiteClues,
        blackClues: blackClues,
        game: getUnfilledCluesMessage.game,
        guesses: { guess1: undefined, guess2: undefined, guess3: undefined, fixed: false },
        whiteFinished: false,
        blackFinished: false,
      }
    case WebsocketActionType.UpdateGuess:
      const updateGuessMessage = action as UpdateGuessMessage
      return {
        ...state,
        guesses: {
          guess1: updateGuessMessage.index === 1 ? updateGuessMessage.value : state.guesses.guess1,
          guess2: updateGuessMessage.index === 2 ? updateGuessMessage.value : state.guesses.guess2,
          guess3: updateGuessMessage.index === 3 ? updateGuessMessage.value : state.guesses.guess3,
          fixed: false,
        },
      }
    case WebsocketActionType.ValidateGuess:
      const validateGuessMessage = action as ValidateGuessMessage
      const white = getIsWhiteTeam(state.playerName, state.players)
      if (!validateGuessMessage.myTeam) {
        return {
          ...state,
          whiteFinished: white ? state.whiteFinished : true,
          blackFinished: white ? true : state.blackFinished,
        }
      }
      const foe = amFoe(state)
      let clues: Dictionary<Clue>
      if ((white && !foe) || (!white && foe)) {
        clues = copyClueDict(state.whiteClues)
      } else {
        clues = copyClueDict(state.blackClues)
      }
      if (foe) {
        (clues[validateGuessMessage.turn] as Clue).foeGuess1 = validateGuessMessage.value1;
        (clues[validateGuessMessage.turn] as Clue).foeGuess2 = validateGuessMessage.value2;
        (clues[validateGuessMessage.turn] as Clue).foeGuess3 = validateGuessMessage.value3
      } else {
        (clues[validateGuessMessage.turn] as Clue).guess1 = validateGuessMessage.value1;
        (clues[validateGuessMessage.turn] as Clue).guess2 = validateGuessMessage.value2;
        (clues[validateGuessMessage.turn] as Clue).guess3 = validateGuessMessage.value3
      }
      return {
        ...state,
        guesses: {
          guess1: validateGuessMessage.value1,
          guess2: validateGuessMessage.value2,
          guess3: validateGuessMessage.value3,
          fixed: true,
        },
        whiteClues: clues[validateGuessMessage.turn]?.whiteTeam ? clues : state.whiteClues,
        blackClues: clues[validateGuessMessage.turn]?.whiteTeam ? state.blackClues : clues,
        whiteFinished: white ? true : state.whiteFinished,
        blackFinished: white ? state.blackFinished : true,
      }
    case WebsocketActionType.SendChat:
      const sendChatMessage = action as SendChatMessage
      const messages = [...state.chat]
      messages.push({ playerName: sendChatMessage.playerName, message: sendChatMessage.message })
      return {
        ...state,
        chat: messages,
      }
    case WebsocketActionType.GetResult:
      const getResultMessage = action as GetResultMessage
      whiteClues = copyClueDict(state.whiteClues)
      blackClues = copyClueDict(state.blackClues)
      for (const clue of getResultMessage.clues) {
        if (clue.whiteTeam) whiteClues[clue.turn] = clue
        else blackClues[clue.turn] = clue
      }
      return {
        ...state,
        whiteClues: whiteClues,
        blackClues: blackClues,
        game: getResultMessage.game,
      }
    case WebsocketActionType.ContinueGame:
      const continueGameMessage = action as ContinueGameMessage
      if (continueGameMessage.game.state === GameStateEnum.FindClues) {
        players = state.players.map(p => ({ ...p, isSpy: false }))
      } else {
        players = state.players
      }
      return {
        ...state,
        guesses: initialState.guesses,
        game: continueGameMessage.game,
        players: players,
        whiteFinished: false,
        blackFinished: false,
      }

    // Default: do nothing
    default:
      return state
  }
}
