import WhiteWords from '../models/WhiteWords'
import BlackWords from '../models/BlackWords'

export function extractWords(words: WhiteWords | BlackWords): string[] {
  const out = [] as string[]

  if (Object.prototype.hasOwnProperty.call(words, 'whiteWord1')) {
    const white = words as WhiteWords
    out.push(
      white.whiteWord1,
      white.whiteWord2,
      white.whiteWord3,
      white.whiteWord4,
    )
  } else {
    const black = words as BlackWords
    out.push(
      black.blackWord1,
      black.blackWord2,
      black.blackWord3,
      black.blackWord4,
    )
  }

  return out
}
