import WebsocketAction from '../WebsocketAction'
import { WebsocketActionType } from '../ActionType'

export interface SendClues extends WebsocketAction {
  type: number
  clue1: string,
  clue2: string,
  clue3: string,
  socket: { send: boolean }
}

export function sendClues(
  clue1: string,
  clue2: string,
  clue3: string,
): SendClues {
  return {
    type: WebsocketActionType.SendClues,
    clue1: clue1,
    clue2: clue2,
    clue3: clue3,
    socket: { send: true },
    local: true,
  }
}
