import WebsocketAction from '../WebsocketAction'
import { WebsocketActionType } from '../ActionType'

export interface ToggleSpy extends WebsocketAction {
  type: number
  isSpy: boolean
  socket: { send: boolean }
}

export function toggleSpy(isSpy: boolean): ToggleSpy {
  return {
    type: WebsocketActionType.ToggleSpy,
    isSpy: isSpy,
    socket: { send: true },
    local: true,
  }
}
