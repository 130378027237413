import WebsocketAction from '../WebsocketAction'
import { WebsocketActionType } from '../ActionType'

export interface RegisterPlayer extends WebsocketAction {
  type: number
  name: string
  whiteTeam: boolean
  socket: { send: boolean }
}

export function registerPlayer(
  name: string,
  whiteTeam: boolean,
): RegisterPlayer {
  return {
    type: WebsocketActionType.RegisterPlayer,
    name: name,
    whiteTeam: whiteTeam,
    socket: { send: true },
    local: true,
  }
}
