import React from 'react'
import './WordDisplay.scss'
import { Grid } from '@material-ui/core'

interface Props {
  words: string[]
}

const WordDisplay: React.FC<Props> = (props: Props) => {
  return (
    <Grid
      container
      item
      justify="center"
      xs={12}
      spacing={2}
      className="word-display"
    >
      {props.words.map((word, i) => (
        <Grid container item xs={11} sm={6} md={3} lg={2} key={i}>
          <Grid item xs={12} className="content-card">
            <span>{word}</span>
            <span className="number">{i + 1}</span>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default WordDisplay
