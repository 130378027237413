import WebsocketAction from '../WebsocketAction'
import { WebsocketActionType } from '../ActionType'

export interface LaunchGame extends WebsocketAction {
  type: number
  launching: boolean
  socket: { send: boolean }
}

export function launchGame(): LaunchGame {
  return {
    type: WebsocketActionType.LaunchGame,
    launching: true,
    socket: { send: true },
    local: true,
  }
}
