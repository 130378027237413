import React, { Component, ReactNode } from 'react'
import AppState from '../../../store/AppState'
import Clue from '../../../models/Clue'
import './Board.scss'
import { connect } from 'react-redux'
import { getWords } from '../../../store/websocketActions/GetWords'
import { Action } from '@reduxjs/toolkit'
import { Grid } from '@material-ui/core'
import WordDisplay from './WordDisplay'
import SpyPanel from './SpyPanel'
import { TabPanel } from '../common/TabPanel'
import { TabContainer } from '../common/TabContainer'
import { GameStateEnum } from '../../../utils/GameStateEnum'
import PlayerList from '../common/PlayerList'
import GuessingPanel from './GuessingPanel'
import ClueSheet from './tabs/ClueSheet'
import ClueSummary from './tabs/ClueSummary'
import { getIsWhiteTeam } from '../../../utils/players'
import { clueDictToList } from '../../../utils/clues'
import ResultPanel from './ResultPanel'

interface Props {
  gameState: number
  words?: string[]
  whiteTeam: boolean
  whiteClues: Clue[]
  blackClues: Clue[]

  getWords: () => Action
}

class Board extends Component<Props> {
  constructor(props: Props) {
    super(props)

    this.drawAppState = this.drawAppState.bind(this)
  }

  componentDidMount() {
    if (!this.props.words) {
      this.props.getWords()
    }
  }

  drawAppState(): ReactNode {
    switch (this.props.gameState) {
      case GameStateEnum.FindClues:
        return <SpyPanel />
      case GameStateEnum.GuessBoth:
        return <GuessingPanel mode="both" />
      case GameStateEnum.GuessOne:
        return <GuessingPanel mode="first" />
      case GameStateEnum.GuessTwo:
        return <GuessingPanel mode="second" />
      case GameStateEnum.ResultOne:
        return <ResultPanel mode="one" />
      case GameStateEnum.ResultGlobal:
        return <ResultPanel mode="global" />
    }
  }

  render(): ReactNode {
    return (
      <Grid container item justify="center" xs={12} className="board">
        <WordDisplay words={this.props.words ?? []} />
        <Grid container item justify="center" xs={12} spacing={4}>
          <PlayerList md={2} lg={2} color="white" />
          <Grid container item xs={12} sm={10} md={8}>
            {this.drawAppState()}
          </Grid>
          <PlayerList md={2} lg={2} color="black" />
          <TabContainer>
            <TabPanel label="Fiche indices Blancs">
              <ClueSheet white={true} clues={this.props.whiteClues} />
            </TabPanel>
            <TabPanel label="Fiche indices Noirs">
              <ClueSheet white={false} clues={this.props.blackClues} />
            </TabPanel>
            <TabPanel label="Résumé indices Blancs">
              <ClueSummary white={true} words={this.props.whiteTeam ? this.props.words ?? [] : []} clues={this.props.whiteClues} />
            </TabPanel>
            <TabPanel label="Résumé indices Noirs">
              <ClueSummary white={false} words={!this.props.whiteTeam ? this.props.words ?? [] : []} clues={this.props.blackClues} />
            </TabPanel>
          </TabContainer>
          <PlayerList xs={12} sm={10} />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  gameState: state.game?.state ?? 0,
  words: state.words,
  whiteTeam: getIsWhiteTeam(state.playerName, state.players),
  whiteClues: clueDictToList(state.whiteClues),
  blackClues: clueDictToList(state.blackClues),
})

export default connect(mapStateToProps, { getWords })(Board)
