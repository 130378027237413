import React, { Component, ReactNode } from 'react'
import './Index.scss'
import * as H from 'history'
import { Grid } from '@material-ui/core'
import CreateGameForm from './CreateGameForm'
import GameList from './GameList'
import { connect } from 'react-redux'
import { leaveGame } from '../../store/websocketActions/LeaveGame'
import { Action } from '@reduxjs/toolkit'

interface Props {
  history: H.History

  leaveGame: () => Action
}

class Index extends Component<Props> {
  componentDidMount() {
    this.props.leaveGame()
  }

  render(): ReactNode {
    return (
      <Grid container item justify="center" xs={12}>
        <CreateGameForm history={this.props.history} />
        <GameList history={this.props.history} />
      </Grid>
    )
  }
}

export default connect(undefined, { leaveGame })(Index)
