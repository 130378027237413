import WebsocketAction from '../WebsocketAction'
import { WebsocketActionType } from '../ActionType'

export interface LeaveGame extends WebsocketAction {
  type: number
  socket: { send: boolean }
}

export function leaveGame(): LeaveGame {
  return {
    type: WebsocketActionType.LeaveGame,
    socket: { send: true },
    local: true,
  }
}
