import React, { Component, ReactNode } from 'react'
import Clue from '../../../models/Clue'
import Game from '../../../models/Game'
import AppState from '../../../store/AppState'
import { connect } from 'react-redux'
import { Button, Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Action, Dictionary } from '@reduxjs/toolkit'
import { continueGame } from '../../../store/websocketActions/ContinueGame'
import './ResultPanel.scss'

interface Props {
  blackClues: Dictionary<Clue>
  whiteClues: Dictionary<Clue>
  game: Game
  
  continueGame: () => Action
  
  mode: 'one' | 'global'
}

interface State {
  clues: Clue[]
}

class ResultPanel extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    
    const clues = [(props.game.whiteStarting
      ? props.whiteClues[props.game.turn]
      : props.blackClues[props.game.turn]) as Clue]
    if (props.mode === 'global') {
      clues.push((props.game.whiteStarting
        ? props.blackClues[props.game.turn]
        : props.whiteClues[props.game.turn]) as Clue,
      )
    }
    
    this.state = {
      clues: clues,
    }
    
    this.stepIndex = this.stepIndex.bind(this)
    this.error = this.error.bind(this)
    this.success = this.success.bind(this)
    this.buttonText = this.buttonText.bind(this)
    this.continue = this.continue.bind(this)
  }
  
  stepIndex(): 3 | 5 {
    return this.props.mode === 'one' || this.props.game.turn === 1 ? 3 : 5
  }
  
  error(white: boolean): boolean {
    for (const clue of this.state.clues) {
      if (clue.whiteTeam === white) {
        return clue.guess1 !== clue.indication1 ||
          clue.guess2 !== clue.indication2 ||
          clue.guess3 !== clue.indication3
      } 
    }
    return false
  }

  success(white: boolean): boolean {
    if (this.props.game.turn === 1) return false 
    for (const clue of this.state.clues) {
      if (clue.whiteTeam !== white) {
        return clue.foeGuess1 === clue.indication1 &&
          clue.foeGuess2 === clue.indication2 &&
          clue.foeGuess3 === clue.indication3
      }
    }
    return false
  }
  
  buttonText(): string {
    if (this.props.mode === 'one') return 'Continuer'
    if (this.props.game.turn === 8 ||
      this.props.game.whiteErrors > 1 ||
      this.props.game.whiteSuccess > 1 ||
      this.props.game.blackErrors > 1 ||
      this.props.game.blackSuccess > 1) return 'Fin de partie'
    return 'Manche suivante'
  }
  
  continue() {
    this.props.continueGame()
  }
  
  render(): ReactNode {
    return (
      <Grid container item justify="center" xs={12} className="result-panel content-card">
        <Grid item xs={12}>
          <h2>{this.stepIndex()} - Résultats {this.props.mode === 'one' ? 'intermédiaires' : 'de la manche'}</h2>
        </Grid>
        {
          this.state.clues.map((clue, index) => (
            <Grid key={index} item xs={12}>
              <h3>Indices de l&apos;équipe {clue.whiteTeam ? 'blanche' : 'noire'}</h3>
              <h4>Suggestions de l&apos;équipe {clue.whiteTeam ? 'blanche' : 'noire'} :{' '}
                <span className="code">{clue.guess1} {clue.guess2} {clue.guess3}</span></h4>
              {
                clue.foeGuess1 != null &&
                    <h4>Suggestions de l&apos;équipe {clue.whiteTeam ? 'noire' : 'blanche'} :{' '} 
                      <span className="code">{clue.foeGuess1} {clue.foeGuess2} {clue.foeGuess3}</span></h4>
              }
              <h4>Code :{' '}
                <span className="code">{clue.indication1} {clue.indication2} {clue.indication3}</span></h4>
            </Grid>
          ))
        }
        <Grid item xs={12}>
          <h3>Variations de scores :</h3>
          <h4>Équipe blanche :{' '}
            {
              this.success(true) &&
            <span className="successes">+1 <FontAwesomeIcon icon={faCheck} /></span>
            }
            {
              this.error(true) &&
            <span className="errors">+1 <FontAwesomeIcon icon={faTimes} /></span>
            }
          </h4>
          <h4>Équipe noire :{' '}
            {
              this.success(false) &&
            <span className="successes">+1 <FontAwesomeIcon icon={faCheck} /></span>
            }
            {
              this.error(false) &&
            <span className="errors">+1 <FontAwesomeIcon icon={faTimes} /></span>
            }
          </h4>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.continue}
          >
            {this.buttonText()}
          </Button>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  blackClues: state.blackClues,
  whiteClues: state.whiteClues,
  game: state.game ?? {} as Game,
})

export default connect(mapStateToProps, { continueGame })(ResultPanel)
