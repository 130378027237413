import React, { Component, ReactNode } from 'react'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { GridSize } from '@material-ui/core/Grid'
import Game from '../../../models/Game'
import Player from '../../../models/Player'
import AppState from '../../../store/AppState'
import { connect } from 'react-redux'
import './PlayerList.scss'

interface Props {
  players: Player[]
  playerName?: string
  game: Game

  xs?: boolean | GridSize | undefined
  sm?: boolean | GridSize | undefined
  md?: boolean | GridSize | undefined
  lg?: boolean | GridSize | undefined
  color: 'all' | 'white' | 'black'
}

class PlayerList extends Component<Props> {
  public static defaultProps = {
    xs: undefined,
    sm: undefined,
    md: undefined,
    lg: undefined,

    color: 'all',
  } as Props

  render(): ReactNode {
    const whitePlayers = this.props.players.filter((p) => p.whiteTeam)
    const blackPlayers = this.props.players.filter((p) => !p.whiteTeam)
    const rows = []
    for (
      let i = 0;
      i < Math.max(whitePlayers.length, blackPlayers.length);
      i++
    ) {
      rows.push([whitePlayers[i], blackPlayers[i]])
    }

    const displayClass =
      (this.props.xs ? '' : 'xs-none ') +
      (this.props.sm ? '' : 'sm-none ') +
      (this.props.md ? '' : 'md-none ') +
      (this.props.lg ? '' : 'lg-none')

    return (
      <Grid
        container
        item
        justify="center"
        xs={this.props.xs}
        sm={this.props.sm}
        md={this.props.md}
        lg={this.props.lg}
        className={displayClass}
      >
        <Grid
          container
          item
          justify="center"
          xs={12}
          className="player-list content-card"
        >
          <Grid item xs={12}>
            {this.props.color === 'all' && <h2>Équipes</h2>}
            {this.props.color === 'white' && <h2>Blancs</h2>}
            {this.props.color === 'black' && <h2>Noirs</h2>}
            {
              this.props.color !== 'all' &&
              <h3>
                <FontAwesomeIcon icon={faCheck} />{' '}
                {this.props.color === 'white' ? this.props.game.whiteSuccess : this.props.game.blackSuccess}{' '}
                <FontAwesomeIcon icon={faTimes} />{' '}
                {this.props.color === 'white' ? this.props.game.whiteErrors : this.props.game.blackErrors}{' '}
              </h3>
            }
          </Grid>
          <Grid item xs={12} className="table">
            <TableContainer component={'span'}>
              <Table aria-label="players">
                <TableBody>
                  {
                    this.props.color === 'all' &&
                    this.props.game.whiteSuccess != null &&
                    <TableRow>
                      <TableCell align="center" className="white cell score">
                        <h3>
                          <FontAwesomeIcon icon={faCheck} />{' '}
                          {this.props.game.whiteSuccess}{' '}
                          <FontAwesomeIcon icon={faTimes} />{' '}
                          {this.props.game.whiteErrors}{' '}
                        </h3>
                      </TableCell>
                      <TableCell align="center" className="black cell score">
                        <h3>
                          <FontAwesomeIcon icon={faCheck} />{' '}
                          {this.props.game.blackSuccess}{' '}
                          <FontAwesomeIcon icon={faTimes} />{' '}
                          {this.props.game.blackErrors}{' '}
                        </h3>
                      </TableCell>
                    </TableRow>
                  }
                  {rows.map((row, i) => (
                    <TableRow key={i}>
                      {(this.props.color === 'all' ||
                        (this.props.color === 'white' && row[0])) && (
                        <TableCell
                          align="center"
                          className={
                            'white cell ' +
                            (row[0] && row[0].isSpy ? 'spy' : '')
                          }
                        >
                          {row[0]
                            ? row[0].name +
                              (row[0].name === this.props.playerName
                                ? ' (me)'
                                : '')
                            : ''}
                        </TableCell>
                      )}
                      {(this.props.color === 'all' ||
                        (this.props.color === 'black' && row[1])) && (
                        <TableCell
                          align="center"
                          className={
                            'black cell ' +
                            (row[1] && row[1].isSpy ? 'spy' : '')
                          }
                        >
                          {row[1]
                            ? row[1].name +
                              (row[1].name === this.props.playerName
                                ? ' (me)'
                                : '')
                            : ''}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  players: state.players != null ? state.players : [],
  playerName: state.playerName != null ? state.playerName : '',
  game: state.game ?? {} as Game,
})

export default connect(mapStateToProps)(PlayerList)
