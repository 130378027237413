import WebsocketAction from '../WebsocketAction'
import { WebsocketActionType } from '../ActionType'

export interface SendChat extends WebsocketAction {
  type: number
  message: string
  socket: { send: boolean }
}

export function sendChat(message: string): SendChat {
  return {
    type: WebsocketActionType.SendChat,
    message: message,
    socket: { send: true },
    local: true,
  }
}
