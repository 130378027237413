import AppState from '../store/AppState'
import { getIsWhiteTeam } from './players'
import { GameStateEnum } from './GameStateEnum'

export function amFoe(state: AppState): boolean {
  const white = getIsWhiteTeam(state.playerName, state.players)
  switch (state.game?.state) {
    case GameStateEnum.GuessOne:
      return (white && !state.game?.whiteStarting) || (!white && state.game?.whiteStarting)
    case GameStateEnum.GuessTwo:
      return (white && state.game?.whiteStarting) || (!white && !state.game?.whiteStarting)
    case GameStateEnum.GuessBoth:
    default:
      return false
  }
}