import Clue from '../../../../models/Clue'
import React from 'react'
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'
import './ClueSummary.scss'

interface Props {
  white: boolean
  words: string[]
  clues: Clue[]
}

const ClueSummary: React.FC<Props> = (props: Props) => {
  const hasIndication = (clue: Clue, indication: number) => {
    return clue.indication1 === indication || clue.indication2 === indication || clue.indication3 === indication
  }
  const getClue = (clue: Clue, indication: number) => {
    if (clue.indication1 === indication) return clue.clue1
    if (clue.indication2 === indication) return clue.clue2
    if (clue.indication3 === indication) return clue.clue3
  }
  
  const className = 'clue-summary ' + (props.white ? 'white' : '')
  return (
    <Grid container item xs={12} className={className}>
      <TableContainer component="div" style={{ overflowX: 'auto', width: '100%' }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell align="center">
                1{ props.words.length > 0 ? (' - ' + props.words[0]) : '' }
              </TableCell>
              <TableCell align="center">
                2{ props.words.length > 0 ? (' - ' + props.words[1]) : '' }
              </TableCell>
              <TableCell align="center">
                3{ props.words.length > 0 ? (' - ' + props.words[2]) : '' }
              </TableCell>
              <TableCell align="center">
                4{ props.words.length > 0 ? (' - ' + props.words[3]) : '' }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                {
                  props.clues.filter(c => hasIndication(c, 1)).map((clue, i) => (
                    <span key={i}>{ getClue(clue, 1) }<br /></span>
                  ))
                }
              </TableCell>
              <TableCell align="center">
                {
                  props.clues.filter(c => hasIndication(c, 2)).map((clue, i) => (
                    <span key={i}>{ getClue(clue, 2) }<br /></span>
                  ))
                }
              </TableCell>
              <TableCell align="center">
                {
                  props.clues.filter(c => hasIndication(c, 3)).map((clue, i) => (
                    <span key={i}>{ getClue(clue, 3) }<br /></span>
                  ))
                }
              </TableCell>
              <TableCell align="center">
                {
                  props.clues.filter(c => hasIndication(c, 4)).map((clue, i) => (
                    <p key={i}>{ getClue(clue, 4) }</p>
                  ))
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default ClueSummary
