import React, { ChangeEvent, Component, ReactNode } from 'react'
import { getIsSpy, getIsWhiteTeam } from '../../../utils/players'
import { connect } from 'react-redux'
import { Button, Grid, MenuItem, Select } from '@material-ui/core'
import AppState from '../../../store/AppState'
import Clue from '../../../models/Clue'
import Guess from '../../../models/Guess'
import './GuessingPanel.scss'
import { updateGuess } from '../../../store/websocketActions/UpdateGuess'
import { validateGuess } from '../../../store/websocketActions/ValidateGuess'
import { Action } from '@reduxjs/toolkit'
import { clueDictToList } from '../../../utils/clues'
import Chat from '../common/Chat'
import { getUnfilledClues } from '../../../store/websocketActions/GetUnfilledClues'

interface Props {
  blackClues: Clue[]
  guesses: Guess
  isSpy: boolean
  whiteClues: Clue[]
  whiteFirst: boolean
  whiteTeam: boolean
  words: string[]
  whiteGuessSent: boolean
  blackGuessSent: boolean
  
  getUnfilledClues: () => Action
  updateGuess: (index: 1 | 2 | 3, value: 1 | 2 | 3 | 4) => Action
  validateGuess: (value1: 1 | 2 | 3 | 4, value2: 1 | 2 | 3 | 4, value3: 1 | 2 | 3 | 4) => Action
  
  mode: 'both' | 'first' | 'second'
}

interface State {
  clues: string[]
}

class GuessingPanel extends Component<Props,  State> {
  constructor(props: Props) {
    super(props)
    
    const clue = this.getClue(props)

    this.state = {
      clues: [clue.clue1, clue.clue2, clue.clue3],
    }
    
    this.handleChange = this.handleChange.bind(this)
    this.stepIndex = this.stepIndex.bind(this)
    this.myTurn = this.myTurn.bind(this)
    this.validGuess = this.validGuess.bind(this)
    this.spyLock = this.spyLock.bind(this)
    this.sendGuess = this.sendGuess.bind(this)
    this.drawSelector = this.drawSelector.bind(this)
  }
  
  componentDidMount() {
    if (this.state.clues[0] == null) {
      this.props.getUnfilledClues()
    } 
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.whiteClues.length !== this.props.whiteClues.length || prevProps.blackClues.length !== this.props.blackClues.length) {
      const clue = this.getClue(this.props)
      this.setState({
        clues: [clue.clue1, clue.clue2, clue.clue3],
      })
    }
  }

  getClue(props: Props): Clue {
    let clue: Clue
    switch (props.mode) {
      case 'both':
        if (props.whiteTeam) clue = props.whiteClues[props.whiteClues.length - 1]
        else clue = props.blackClues[props.blackClues.length - 1]
        break
      case 'first':
        if (props.whiteFirst) clue = props.whiteClues[props.whiteClues.length - 1]
        else clue = props.blackClues[props.blackClues.length - 1]
        break
      case 'second':
        if (props.whiteFirst) clue = props.blackClues[props.blackClues.length - 1]
        else clue = props.whiteClues[props.whiteClues.length - 1]
        break
    }
    return clue ?? {} as Clue
  }
  
  stepIndex(): 2 | 4 {
    switch (this.props.mode) {
      case 'both':
      case 'first':
        return 2
      case 'second':
        return 4
    }
  }
  
  myTurn(): boolean {
    switch (this.props.mode) {
      case 'both':
        return true
      case 'first':
        return (this.props.whiteFirst && this.props.whiteTeam) || (!this.props.whiteFirst && !this.props.whiteTeam)
      case 'second':
        return (this.props.whiteFirst && !this.props.whiteTeam) || (!this.props.whiteFirst && this.props.whiteTeam)
    }
  }

  validGuess(): boolean {
    return this.props.guesses.guess1 != null &&
      this.props.guesses.guess2 != null &&
      this.props.guesses.guess3 != null &&
      this.props.guesses.guess1 !== this.props.guesses.guess2 &&
      this.props.guesses.guess1 !== this.props.guesses.guess3 &&
      this.props.guesses.guess2 !== this.props.guesses.guess3
  }
  
  spyLock(): boolean {
    switch (this.props.mode) {
      case 'both':
        return this.props.isSpy
      case 'first':
        return this.props.isSpy &&
          ((this.props.whiteTeam && this.props.whiteFirst) ||
          (!this.props.whiteTeam && !this.props.whiteFirst))
      case 'second':
        return this.props.isSpy &&
          ((!this.props.whiteTeam && this.props.whiteFirst) ||
            (this.props.whiteTeam && !this.props.whiteFirst))
    }
  }
  
  sendGuess() {
    if (this.validGuess()) {
      const guess1 = this.props.guesses.guess1 as 1 | 2 | 3 | 4
      const guess2 = this.props.guesses.guess2 as 1 | 2 | 3 | 4
      const guess3 = this.props.guesses.guess3 as 1 | 2 | 3 | 4
      this.props.validateGuess(guess1, guess2, guess3)
    }
  }
  
  handleChange(event: ChangeEvent<{value: unknown}>, index: number) {
    this.props.updateGuess(index as 1 | 2 | 3,  event.target.value as 1 | 2 | 3 | 4)
  }
  
  drawSelector(index: number): ReactNode {
    const value = index === 1
      ? this.props.guesses.guess1 ?? ''
      : index === 2
        ? this.props.guesses.guess2 ?? ''
        : this.props.guesses.guess3 ?? ''
    return (
      <Grid container item justify="center" xs={12}>
        <Grid item xs={6} style={{ textAlign: 'right', display: 'flex' }}>
          <h4 style={{ margin: 'auto 0.2rem auto auto' }}>Mot associé : </h4>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'left', display: 'flex' }}>
          <Select
            value={value}
            label="Code"
            onChange={(event) => this.handleChange(event, index)}
            style={{ margin: 'auto auto auto 0.2rem' }}
            variant="outlined"
            disabled={this.props.guesses.fixed || this.spyLock()}
          >
            <MenuItem value={1}>1 - { this.myTurn() ? this.props.words[0] : '?' }</MenuItem>
            <MenuItem value={2}>2 - { this.myTurn() ? this.props.words[1] : '?' }</MenuItem>
            <MenuItem value={3}>3 - { this.myTurn() ? this.props.words[2] : '?' }</MenuItem>
            <MenuItem value={4}>4 - { this.myTurn() ? this.props.words[3] : '?' }</MenuItem>
          </Select>
        </Grid>
      </Grid>
    )
  }
  
  render(): ReactNode {
    return (
      <Grid
        container
        item
        justify="center"
        xs={12}
        className="guessing-panel content-card"
      >
        <Grid item xs={12}>
          <h2>{this.stepIndex()} - Deviner le code de {this.myTurn() ? 'mon équipe' : 'l\'équipe adverse'}</h2>
        </Grid>
        {
          ((this.props.whiteTeam && this.props.blackGuessSent) || (!this.props.whiteTeam && this.props.whiteGuessSent)) &&
          <Grid item xs={12}>
            <h3 className="foe-sent">Code adverse envoyé</h3>
          </Grid>
        }
        {
          this.spyLock() &&
          <Grid item xs={12}>
            <h3 className="spy">Vous avez été espion pour ce tour, vous ne pouvez pas participer à cette étape</h3>
          </Grid>
        }
        {
          this.state.clues.map((clue, index) => (
            <Grid key={index} container item xs={11} justify="center" className={'form-container ' + (index === 1 ? 'mid' : '')}>
              <Grid item xs={12}>
                <h3>{clue}</h3>
              </Grid>
              { this.drawSelector(index + 1) }
            </Grid>
          ))
        }
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.sendGuess}
            disabled={!this.validGuess() || this.props.guesses.fixed || this.spyLock()}
          >
            Envoyer le code
          </Button>
        </Grid>
        <Grid container item xs={12} style={{ padding: '0 1rem' }}>
          <Chat disabled={this.spyLock()} />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  blackClues: clueDictToList(state.blackClues),
  guesses: state.guesses,
  isSpy: getIsSpy(state.playerName, state.players),
  whiteClues: clueDictToList(state.whiteClues),
  whiteFirst: state.game?.whiteStarting ?? true,
  whiteTeam: getIsWhiteTeam(state.playerName, state.players),
  words: state.words ?? [],
  whiteGuessSent: state.whiteFinished,
  blackGuessSent: state.blackFinished,
})

export default connect(mapStateToProps, { getUnfilledClues, updateGuess, validateGuess })(GuessingPanel)
